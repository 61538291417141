<template>
  <div class="bg-white md:bg-light">
    <header class="flex items-center justify-center w-full h-50vh">
      <h1
        class="text-3xl font-medium leading-relaxed tracking-wide text-center text-white md:text-4xl mt-32"
      >
        <span class="text-4xl uppercase md:text-6xl">{{ $t('About.header.h1') }}</span>
        <br />
        {{ $t('About.header.name') }}
      </h1>
    </header>
    <div class="container px-5 mx-auto md:px-8">
      <div
        v-for="(item, index) in aboutDetails"
        :key="index"
        data-aos="fade-up"
        :style="index % 2 == 0 ? 'direction: rtl;' : 'direction: ltr;'"
        class="grid items-center grid-cols-3 my-20 overflow-hidden rounded-lg bg-light md:bg-white about-us-item-div"
      >
        <img
          class="object-cover h-full col-span-1 bg-center border-primary"
          :src="images[index]"
          alt=""
          :class="index % 2 == 0 ? 'border-l-8' : 'border-r-8'"
        />
        <div
          :style="$i18n.locale == 'ar' ? 'direction: rtl' : 'direction: ltr'"
          class="col-span-2 p-5 leading-relaxed text-gray-900"
        >
          <h6 class="mb-5 text-xl md:text-5xl text-primary">
            {{ item.title }}
          </h6>
          <p class="text-sm md:text-xl">
            {{ item.description }}
          </p>
        </div>
      </div>

      <Brands />
    </div>
  </div>
</template>
<script>
import Brands from '../components/layouts/Brands'
export default {
  name: 'Home',
  data() {
    return {
      images: [
        '/about-us-images/erwan-hesry-OlQ-NaEyVmQ-unsplash1.png',
        '/about-us-images/our-vision.jpg',
        '/about-us-images/goals.jpg',
        '/about-us-images/our-services.jpg',
        '/about-us-images/our-team.jpg',
      ],
    }
  },
  computed: {
    aboutDetails() {
      return [
        {
          title: this.$t('About.header.h1'),
          description: this.$t('About.firstSection.p'),
        },

        {
          title: this.$t('About.aboutDetails.ourVision.h1'),
          description: this.$t('About.aboutDetails.ourVision.p'),
        },
        {
          title: this.$t('About.aboutDetails.ourGoals.h1'),
          description: this.$t('About.aboutDetails.ourGoals.p'),
        },
        {
          title: this.$t('About.aboutDetails.Ourservices.h1'),
          description: this.$t('About.aboutDetails.Ourservices.p'),
        },
        {
          title: this.$t('About.aboutDetails.AlNawaratTeam.h1'),
          description: this.$t('About.aboutDetails.AlNawaratTeam.p'),
        },
      ]
    },
  },
  components: {
    Brands,
  },
}
</script>
<style lang="scss" scoped>
header {
  background: url(../assets/andy-li-CpsTAUPoScw-unsplash1.png);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1025px) {
  .about-us-item-div {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .about-us-item-div img {
    width: 100%;
  }
}
</style>
